<template>
    <div class="page">
        <h1 class="module-h1" v-html="learning_module_page.name[lang]" />
        <div class="module-fonts module-content" v-if="learning_module_page.content" v-html="learning_module_page.content[lang]" />
    </div>
</template>

<script>
export default {
    props: {
        learning_module_page: {
            type: Object,
            required: true,
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
        }
    },
}
</script>