<template>
    <div class="module grid">
        <nav v-if="learning_module">
            <label 
                class="dashboard-nav-heading" 
                v-html="learning_module.name[lang]" 
            />

            <button
                :class="['dashboard-nav-link', current_learning_module_page.id == page.id && 'router-link-active']" 
                v-for="(page, index) in learning_module.published_pages"
                :key="page.id"
                @click="setCurrentPage(index)"
                v-html="page.name[lang]" 
            />
        </nav>
        <div class="main">
            <LearningModulePage 
                v-if="current_learning_module_page"
                :key="current_learning_module_page.id" 
                :learning_module_page="current_learning_module_page"  />
        </div>
    </div>
</template>

<script>
import { promiseGetRequest } from '@/assets/js/axios-utilities'

import LearningModulePage from '@/components/LearningModulePage'

export default {
    components: {
        LearningModulePage
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            learning_module: null,

            current_learning_module_page: null,
        }
    },
    computed: {
        learning_module_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        },
    },
    created() {
        this.getLearningModule();
        this.setFirstPage();
        
    },
    methods: {
        // Requests
        getLearningModuleRequest() {
            return promiseGetRequest(`learning-module/${this.learning_module_id}`);
        },

        // Functions
        async getLearningModule() {
            this.$store.dispatch('loading', true);
            const response = await this.getLearningModuleRequest();
            this.$store.dispatch('loading', false);
            this.learning_module = response.data;
        },
        setFirstPage() {
            if (this.learning_module) {
                if (this.learning_module.published_pages.length > 0) {
                    this.current_learning_module_page = this.learning_module.published_pages[0];
                }
            }
        },
        setCurrentPage(index) {
            this.current_learning_module_page = this.learning_module.published_pages[index];
        }
    },
    watch: {
        learning_module: function() {
            this.setFirstPage();
        }
    }
}
</script>

<style lang="scss" scoped>

nav { 
    
    a {
        display: block;
    }

    & > * + * {
        margin-block-start: 1rem;
    }
}

.dashboard-nav-link {
    text-align: left;

    background: none;
    border: none;
    cursor: pointer;
}

.main {
    max-width: 50rem;
}

.module {
    grid-template-columns: 1fr 2fr;
}

@media (max-width: 50rem) {
    .grid {
        display: block;

        nav + div {
            margin-top: 3rem;
        }
    }
}

</style>